<template>
  <div
    class="d-flex flex-column flex-xl-row align-items-center justify-content-between m-1 flex-nowrap white-text mb-4"
  >
    <!-- DATE -->
    <div class="d-flex d-xl-none align-items-center">
      <span>
        {{ dateDrawn }}
      </span>
      <span
        v-if="showTotal"
        class="ml-3"
        style="font-size: 1.2rem"
      >
        {{ outOfSix }} / 6
      </span>
    </div>
    <div class="d-flex align-items-center justify-content-between mx-auto" style="width: 100%;max-width: 800px; overflow: none">
      <div
        class="text-wrap rowBoxContainer text-center p-1 d-none d-xl-block m-1"
        style="font-size: 0.9rem;padding:5px !important;"
      >
        <div
          class="textBasedResults"
          style="font-size: 13px;"
        >
          {{ dateDrawn }}
        </div>
      </div>
      <div
        v-for="(drawRow, i) in drawRow"
        :key="i"
        class="rowBoxContainer m-1"
      >
        <!-- HERO -->
        <div
          v-if="drawRow.row === 'Hero'"
          style="overflow: hidden; width: 100%; height: 100%;"
        >
          <img
            
            class="heroObjectPosition"
            :class="draw.hero == 1 || draw.hero == 7 ? 'tallHero' : draw.hero == 6 || draw.hero == 8 ? 'smallHero' : ''"
            style="height: 100%; width: 99%; object-fit: cover; overflow: hidden;"
            :src="require(`@/assets/charSelect/${draw.hero}.colour.png`)"
          >
        </div>
        <!-- SPORT -->
        <img
          v-else-if="drawRow.row === 'Sport'"
          style="height: 100%; width: 100%; object-fit: contain;"
          :src="require(`@/assets/sports/${sportImagePath}.back.png`)"
        >
        <!-- COLOUR -->
        <div
          v-else-if="drawRow.row === 'Colour'"
          style="width: 100%; height: 100%"
          class="p-1 d-flex align-items-center justify-content-center"
        >
          <div
            style="height:100%; width: 100%"
            class="d-flex align-items-center justify-content-center"
            :style="{'background': drawColourHex }"
          >
            <span
              class="black-text drawColourText"
              :class="drawColourReadable === 'PURPLE' || drawColourReadable === 'GREEN' || drawColourReadable === 'BLUE' ? 'white-text' : 'black-text'"
            >{{ drawColourReadable }}</span>
          </div>
        </div>
        <!-- NUMBER -->
        <div
          v-else-if="drawRow.row === 'Number'"
          class="textBasedResults"
        >
          {{ drawRow.result }}
        </div>
        <!-- initial 1 -->
        <div
          v-else-if="drawRow.row === 'Initial 1'"
          class="textBasedResults"
        >
          {{ drawRow.result }}
        </div>
        <!-- initial 2 -->
        <div
          v-else-if="drawRow.row === 'Initial 2'"
          class="textBasedResults"
        >
          {{ drawRow.result }}
        </div>
        <div
          v-if="Object.keys(selectedHero).length !== 0"
          style="overflow: hidden"
        >
          <Star
            v-if="drawRow.result === drawRow.card"
            image-path="star.svg"
          />
          <div
            v-if="drawRow.result === drawRow.card"
            class="resultsIcon"
          >
            <i class="fa fa-check win" />
          </div>
          <div
            v-else
            class="resultsIcon"
          >
            <i class="fa fa-times loss" />
          </div>
        </div>
      </div>
      <div
        v-if="showTotal"
        class="text-wrap d-none d-xl-flex rowBoxContainer text-center p-1 m-1"
        style="font-size: 1.2rem"
      >
        {{ outOfSix }} / 6
      </div>
    </div>
  </div>
</template>

<script>
import Star from '../UI/stars.vue'

export default {
  name: 'ResultsRow',
  components: {
    Star
  },
  props: {
    draw: {
      type: Object,
      default: () => {}
    },
    selectedHero: {
      type: Object,
      default: () => {}
    },
    showTotal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    outOfSix () {
      let count = 0
      if (this.draw.DateDrawn > this.selectedHero.StartFrom) {
        if (this.draw.hero === this.selectedHero.Hero) { count++ }
        if (this.draw.sport === this.selectedHero.Sport) { count++ }
        if (this.drawColourHex === this.selectedHero.Colour) { count++ }
        if (this.draw.number === this.selectedHero.Number) { count++ }
        if (this.drawInitial(this.draw.letterOne) === this.selectedHero.FirstInitial) { count++ }
        if (this.drawInitial(this.draw.letterTwo) === this.selectedHero.SecondInitial) { count++ }
      }
      return count
    },
    dateDrawn () {
      return new Date(this.draw.DateDrawn).toLocaleString('default', { weekday: 'short', day: 'numeric', year: 'numeric', month: 'short' })
    },
    drawRow () {
      const drawRow = [
        { row: 'Hero', result: this.draw.hero, card: this.selectedHero.Hero },
        { row: 'Colour', result: this.drawColourHex, card: this.selectedHero.Colour },
        { row: 'Initial 1', result: this.drawInitial(this.draw.letterOne), card: this.selectedHero.FirstInitial },
        { row: 'Initial 2', result: this.drawInitial(this.draw.letterTwo), card: this.selectedHero.SecondInitial },
        { row: 'Sport', result: this.draw.sport, card: this.selectedHero.Sport },
        { row: 'Number', result: this.draw.number, card: this.selectedHero.Number },
      ]
      return drawRow
    },
    sportImagePath () {
      let sport = ''
      switch (this.draw.sport) {
        case 1: sport = 'football'; break
        case 2: sport = 'rugby'; break
        case 3: sport = 'cricket'; break
        case 4: sport = 'golf'; break
        case 5: sport = 'basketball'; break
        case 6: sport = 'netball'; break
        case 7: sport = 'americanfootball'; break
        case 8: sport = 'tennis'; break
        case 9: sport = 'boxing'; break
      }
      return sport
    },
    drawColourHex () {
      let colour = ''
      switch (this.draw.colour) {
        case 1: colour = '#ff0000'; break
        case 2: colour = '#0000ff'; break
        case 3: colour = '#ffff00'; break
        case 4: colour = '#008000'; break
        case 5: colour = '#ff9900'; break
        case 6: colour = '#800080'; break
        case 7: colour = '#fa42d2'; break
        case 8: colour = '#ffffff'; break
      }
      return colour
    },
    drawColourReadable () {
      let colour = ''
      switch (this.draw.colour) {
        case 1: colour = 'RED'; break
        case 2: colour = 'BLUE'; break
        case 3: colour = 'YELLOW'; break
        case 4: colour = 'GREEN'; break
        case 5: colour = 'ORANGE'; break
        case 6: colour = 'PURPLE'; break
        case 7: colour = 'PINK'; break
        case 8: colour = 'WHITE'; break
      }
      return colour
    }
  },
  methods: {
    drawInitial (number) {
      let letter = ''
      switch (number) {
        case 1: letter = 'A'; break
        case 2: letter = 'B'; break
        case 3: letter = 'C'; break
        case 4: letter = 'D'; break
        case 5: letter = 'E'; break
        case 6: letter = 'F'; break
        case 7: letter = 'G'; break
        case 8: letter = 'H'; break
        case 9: letter = 'I'; break
        case 10: letter = 'J'; break
        case 11: letter = 'K'; break
        case 12: letter = 'L'; break
        case 13: letter = 'M'; break
        case 14: letter = 'N'; break
        case 15: letter = 'O'; break
        case 16: letter = 'P'; break
        case 17: letter = 'Q'; break
        case 18: letter = 'R'; break
        case 19: letter = 'S'; break
        case 20: letter = 'T'; break
        case 21: letter = 'U'; break
        case 22: letter = 'V'; break
        case 23: letter = 'W'; break
        case 24: letter = 'X'; break
        case 25: letter = 'Y'; break
        case 26: letter = 'Z'; break
      }
      return letter
    }
  }
}
</script>

<style>
.rowBoxContainer {
  height: 80px;
  width: 80px;
  border: var(--pr-color) 2px solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.rowBoxContainer .resultsIcon {
  position: absolute;
  bottom: -15px;
  left: calc(50% - 15px);
  display: flex;
  align-items:center;
  justify-content: center;
}

.rowBoxContainer .win, .loss {
  background: var(--bg-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.rowBoxContainer .win {
  color: var(--pr-color);
  border: 3px solid var(--pr-color);
}
.rowBoxContainer .loss {
  border: 3px solid red;
  color: red;
}
.textBasedResults {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lt-color);
  font-size: 2.8rem;
  margin-bottom: 3px;
}
@media (max-width: 576px) {
  .drawColourText {
    writing-mode: vertical-rl;
    text-orientation: upright;
    letter-spacing: -5px;
    translate: 0 -3px;
    font-size: 0.8rem;
    margin: 0 !important;
  }
}


.heroObjectPosition {
  object-position: 0px -12px
}
.tallHero {
  object-position: 0px -5px
}
.smallHero {
  object-position: 0px -20px
}
@media (max-width: 992px) {
  .heroObjectPosition {
    object-position: 0px 2px;
    scale: 1.6;
  }
  .tallHero {
    object-position: 0px 10px
  }
  .smallHero {
    object-position: 0px -15px
  }
}
@media (max-width: 576px) {
  .heroObjectPosition {
    object-position: 0px 6px;
    scale: 1.6;
  }
  .tallHero {
    object-position: 0px 10px
  }
  .smallHero {
    object-position: 0px -5px
  }
}
</style>
