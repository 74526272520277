<template>
  <div style="height:100%; overflow-y: auto;font-size: 0.9rem;" class="d-flex flex-column py-3 flex-grow-0">
    <LeftTopPanelTitle
      :loading="loading"
      text="Results"
      icon="clipboard"
      class="flex-shrink-0"
    />
    <ul class="nav nav-tabs d-flex justify-content-around my-3 font-weight-bold">
      <li
        v-for="(page, i) in pages"
        :key="i"
        class="nav-item text-center text-nowrap flex-grow-1"
      >
        <a
          :title="page.pageName"
          class="nav-link primary-colour"
          :class="currentPage === page.pageName ? 'active' : 'white-text font-weight-light'"
          @click="currentPage = page.pageName"
        >
          <span
            :class="{'d-none' : currentPage != page.pageName}"
            class="d-sm-inline mr-2"
          >{{ page.pageName }}</span>
          <mdb-icon :icon="page.icon" />
        </a>
      </li>
    </ul>

    <div style="overflow-y: auto;">
      <ResultsChecker
        v-if="currentPage === 'Results Checker'"
        @loading="setLoading($event)"
      />
      <AllResults
        v-if="currentPage === 'All Results'"
        @loading="setLoading($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'
import ResultsChecker from './resultsChecker.vue'
import AllResults from './allResults.vue'

export default {
  name: 'Results',
  components: {
    LeftTopPanelTitle, ResultsChecker, AllResults
  },
  data () {
    return {
      currentPage: 'Results Checker',
      loading: false,
      pages: [
        { pageName: 'Results Checker', icon: 'money-bill-wave' },
        { pageName: 'All Results', icon: 'users' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  }
}
</script>
