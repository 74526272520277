<template>
  <transition-group
    name="staggered-fade"
    tag="div"
    @enter="handleEnter"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </transition-group>
</template>

<script>
export default {
  name: 'FadeList',
  data() {
    return {
      delay: 100
    }
  },
  methods: {
    handleEnter (el) {
      const index = [...el.parentNode.children].indexOf(el)
      el.style.opacity = 0
      el.style.transform = "scale(0.9)"
      setTimeout(() => {
        el.style.transition = "opacity 500ms, transform 500ms"
        el.style.opacity = 1
        el.style.transform = "scale(1)"
      }, index * this.delay)
    }
  }
};
</script>

<style scoped>
.staggered-fade-enter-active {
  transition-delay: 0s;
}

.staggered-fade-enter-to {
  opacity: 1;
  transform: scale(1);
}
</style>