<template>
  <div
    v-if="previousResults.length > 0"
    class="d-flex flex-column mt-3"
  >
    <FadeList>
      <div v-for="(draw, i) in previousResults" :key="i">
        <ResultsRow
          :selected-hero="{}"
          :show-total="false"
          :draw="draw"
        />
      </div>
    </FadeList>
  </div>
  <div
    v-else
    class="d-flex flex-column mt-3 primary-colour text-center" 
  >
    Sorry, there are no draw results available at the moment
  </div>
</template>

<script>
import baseService from '@/api-services/base.service'
import { mapGetters } from 'vuex'
import ResultsRow from './resultsRow.vue'
import FadeList from '@/components/UI/fadeList.vue'

export default {
  name: 'AllResults',
  components: {
    ResultsRow, FadeList
  },
  data () {
    return {
      previousResults: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  mounted () {
    this.getAllHeroResults()
  },
  methods: {
    async getAllHeroResults () {
      this.loading = true
      try {
        const res = await baseService.getAllHeroResults()
        this.previousResults = res.data
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
}
</script>
